.box-input {
  width: 100%;
  height: 38px;
  border: 1px solid #eaeaf4;
  background-color: #f5f7fb;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.box-input-red {
  width: 100%;
  height: 38px;
  border: 1px solid #f5222d;
  background-color: #f5f7fb;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.text-input {
  outline: none;
  border: none;
  background-color: #f5f7fb;
  height: 38px;
  width: 255px;
  margin-left: 10px;
  font-size: 14px;
}

.box-error {
  background-color: #fdf1f1;
  height: 32px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #f6a39e;
  line-height: 30px;
  width: 100%;
  border-radius: 4px;
}
.authen-modal {
  position: absolute;
  width: 384px;
  height: 576px;
  left: 200px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
}
.authen-modal {
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 50px;
  text-align: left;
}
.ant-input {
  background-color: #f5f7fb;
}
input:hover {
  border-color: none !important;
}
