.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 10;
}
.navbar {
  background-color: white;
  height: 60px;
  line-height: 60px;
  padding-left: 1em;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .menu {
    display: none;
  }
}
.navbar-right {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
}
.line-bg {
  width: 1px;
  height: 40px;
  background-color: #eff0f9;
  margin-left: 10px;
  margin-right: 10px;
}
.manage-order-menu {
  margin-top: 60px !important;
}
