.ant-layout-content {
  padding: 0 10px;
  padding-top: 12px;
}
.option-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.ant-modal-header {
  padding: 0;
}
.ant-modal-close-x {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-modal-footer {
  border-top: none;
  padding: 10px 0;
  text-align: center;
}
.ant-modal-body {
  padding-left: 10px;
  padding-right: 10px;
}
.overview-item {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #720d5d;
  border-color: #720d5d;
}
.confirm-popup .ant-modal-header {
  text-align: center;
  padding: 16px;
  font-weight: bold !important;
  font-size: 16px;
}
.confirm-popup .ant-modal-body {
  text-align: center;
}
.confirm-button {
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e12a4e;
  color: red;
  border-radius: 4px;
  width: 132px;
  height: 40px;
  margin-left: 16px;
  cursor: pointer;
}
.cancel-button {
  padding: 8px 16px;
  background: #adb5bd;
  border: none;
  color: white;
  border-radius: 4px;
  width: 132px;
  height: 40px;
  cursor: pointer;
}
