* {
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
