.action-menu li {
  height: 40px;
  line-height: 40px;
}
.action-menu li:hover a {
  color: #248eff !important;
}
.ant-menu-item::after {
  border-right: 3px solid #720d5d !important;
}
.ant-menu-item-selected a {
  color: black !important;
}
.ant-menu-item-selected:hover a {
  color: #248eff !important;
}
.ant-menu-item-selected {
  background-color: white !important;
}
.ant-modal-title {
  font-weight: 900;
  font-size: 16px !important;
  color: black !important;
  font-family: "Roboto-Bold";
}

.action-menu .ant-dropdown-menu-item:hover svg path {
  fill: #007bff !important;
}
.modal-upload-right {
  top: 0 !important;
  max-width: 500px;
  height: 100vh;
}
.modal-upload-right .ant-modal-content {
  padding-left: 20px;
  padding-right: 20px;
}
.modal-upload-right .ant-modal-content,
.ant-modal-body {
  height: 100% !important;
}
.modal-upload-right .ant-modal-body {
  height: 60% !important;
}
.modal-upload-right .ant-modal-close {
  left: 0 !important;
}
.modal-upload-right .ant-modal-close-x {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 25px;
}
.modal-upload-right .ant-modal-header {
  border-bottom: none;
}

.modal-upload-right .ant-modal-title {
  padding-top: 30px;
  text-align: center;
}
.upload-progress .ant-progress-inner {
  border: 1px solid #52c419;
}
.out-of-stock-modal .ant-modal-content {
  width: 320px;
}

.return-modal .ant-modal-content {
  width: 420px;
}
.confirm-popup .ant-modal-close-x {
  position: absolute;
  top: -50px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  color: white;
}
.confirm-popup textarea:focus {
  background-color: white !important;
}
.modal-upload-confirm .ant-modal-body {
  text-align: left !important;
  width: 320px;
}
.manage-order-menu {
  .ant-menu-item:hover {
    svg path {
      fill: #40a9ff;
    }
  }
}
.over-view-item:hover {
  border: 1px solid #720d5d;
  color: #720d5d;
}
.over-view-item:hover span {
  color: #720d5d;
}
.over-view-item:hover p {
  color: black;
}
th {
  font-weight: 700 !important;
  font-family: Roboto-Bold;
}
.ant-table-column-title {
  font-weight: 700 !important;
  font-family: Roboto-Bold;
}
.delivery-success {
  background: rgba(55, 179, 74, 0.1);
  border: 1px solid #37b34a;
  border-radius: 4px;
  padding: 9px 8px;
  height: 40px;
  width: 114px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #37b34a;
}
.delivering-process {
  background: rgba(0, 123, 255, 0.1);
  border: 1px solid #007bff;
  border-radius: 4px;
  padding: 9px 8px;
  height: 40px;
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #007bff;
}
.outofstock-process {
  background: rgba(225, 42, 78, 0.1);
  border: 1px solid #e12a4e;
  border-radius: 4px;
  padding: 9px 8px;
  height: 40px;
  width: 97px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #e12a4e;
}

.return-process {
  background: rgba(234, 134, 18, 0.1);
  border: 1px solid #ea8612;
  border-radius: 4px;
  padding: 9px 8px;
  height: 40px;
  width: 95px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ea8612;
}
.waiting-process {
  background: rgba(114, 13, 93, 0.1);
  border: 1px solid #720d5d;
  border-radius: 4px;
  padding: 9px 8px;
  height: 40px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #720d5d;
}
.fail-process {
  background: rgba(172, 0, 0, 0.1);
  border: 1px solid #ac0000;
  border-radius: 4px;
  padding: 9px 8px;
  height: 40px;
  width: 95px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ac0000;
}
.success-modal .ant-modal-title {
  color: green !important;
}
.success-modal .confirm-button {
  color: white;
  background: #37b34a;
  border: #37b34a;
}
